import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CategoryListComponent} from "./category-list.component";
import {RouterModule} from "@angular/router";
import {ImageCacheBustModule} from "../../directives/image-cache-bust.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ImageCacheBustModule,
    ],
    declarations: [
        CategoryListComponent,
    ],
    exports: [
        CategoryListComponent,
    ]
})

export class CategoryListModule {}