/**
 * Provides functionality for listing of sub-categories for the given category
 */

import {Component, Input} from '@angular/core';
import {CategoryItemSelector} from "../../interfaces/general";
import {SettingsService} from "../../services/settings.service";

@Component({
    selector: 'cmp-category-listing',
    templateUrl: '../../tpl/category-list.html'
})

export class CategoryListComponent {

    @Input() categories: CategoryItemSelector[];
    catImagePathPrefix: string;

    constructor (private seSvc: SettingsService) {
        this.catImagePathPrefix = this.seSvc.settings.imageServerPathPrefix + '/fotocache/categorysmall/images/cathome/';
    }

}
